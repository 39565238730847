import React, { useState, useEffect } from 'react';

const Video = (params) => {
  const useWindowWidth = (parent) => {
    const [windowWidth, setWindowWidth] = useState(undefined);

    const handleResize = () => {
      setWindowWidth(document.getElementById(parent).clientWidth);
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
  };

  const videoID = 567011902;
  const width = useWindowWidth(params.parent);

  return (
    <iframe className="gap-top-md" title="video" width={width} height={width * 0.5625} src={`https://player.vimeo.com/video/${videoID}?autoplay=0`} allow="accelerometer; autoplay; gyroscope; picture-in-picture" allowFullScreen />
  );
};

export default Video;
