import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const DescriptionSection = () => {
  const data = useStaticQuery(graphql`
    {
        allImageSharp {
            nodes {
                fluid {
                    src
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        } 
    }
`);

  const WB2 = data.allImageSharp.nodes;

  const [role, setRole] = useState('Waldbesitzer');

  const roles = ['Waldbesitzer', 'Spediteur'];

  const features = [
    {
      waldbesitzer:
  <p>
    Der Waldbesitzer bekommt
    <h2>eine digitale Lieferabfrage</h2>
    {' '}
    in der er dem Holzverarbeiter mitteilt, wieviel Holz er liefern möchte.
  </p>,
      spediteur:
  <p>
    Die Spedition bekommt
    {' '}
    <h2>eine digitale Lieferabfrage</h2>
    , in der sie dem Holz-verarbeiter ihre Lieferkapazitäten mitteilt.
  </p>,
      WBimg: 'WB1',
      SPimg: 'SP1',
      WBcolor: 'white',
      SPcolor: 'white',
    },
    {
      waldbesitzer:
  <p>
    Der Holzverarbeiter sendet dem Waldbesitzer
    {' '}
    <h2>ein Preisangebot</h2>
    {' '}
    für die Lieferplanung zu.
  </p>,
      spediteur:
  <p>
    Durch die Lieferplanung der Waldbesitzer können diese der Spedition als
    {' '}
    <h2>Avisierung</h2>
    {' '}
    bereitgestellt werden.
  </p>,
      WBimg: 'WB2',
      SPimg: 'SP2',
      WBcolor: 'black',
      SPcolor: 'white',
    },
    {
      waldbesitzer:
  <p>
    Falls der Waldbesitzer das Angebot akzeptiert wird
    {' '}
    <h2>ein Liefervertrag </h2>
    {' '}
    erstellt.
  </p>,
      spediteur:
  <p>
    {' '}
    <h2>Transportaufträge</h2>
    {' '}
    digital bereitgestellt, schaffen eine tagesaktuelle Übersicht der Abfuhrdaten.
  </p>,
      WBimg: 'WB3',
      SPimg: 'SP3',
      WBcolor: 'white',
      SPcolor: 'black',
    },
    {
      waldbesitzer:
  <p>
    Der Waldbesitzer stellt seine
    {' '}
    <h2>Holzlisten</h2>
    {' '}
    während des Einschlags über das Portal bereit.
    {' '}
  </p>,
      spediteur:
  <p>
    <h2>Der digitale Lieferschein </h2>
    {' '}
    wird vom Fahrer erstellt und an den jeweiligen Holzverarbeiter versendet.
  </p>,
      WBimg: 'WB4',
      SPimg: 'SP4',
      WBcolor: 'black',
      SPcolor: 'black',
    },
    {
      waldbesitzer:
  <p>
    Der Vertrag wird automatisch aktualisiert und
    {' '}
    <h2>die Abrechnung</h2>
    {' '}
    direkt digital bereitgestellt..
  </p>,
      spediteur:
  <p>
    Der Vertrag wird automatisch aktualisiert und
    {' '}
    <h2>die Abrechnung</h2>
    {' '}
    direkt digital bereitgestellt.
  </p>,
      WBimg: 'WB-SP5',
      SPimg: 'WB-SP5',
      WBcolor: 'white',
      SPcolor: 'white',
    }];

  return (
    <section id="OTHER">
      <div className="content-wrapper fixed-width-md">
        <h1>Der Prozess in 5 einfachen Schritten</h1>
        <div className="wrapper-label gap-bottom-lg fixed-width-sm gap-top-lg">
          {roles.map((item) => (
            <div key={item.id} className="radiusButton" tabIndex={0} role="button" onClick={() => setRole(item)} onKeyDown={() => setRole(item)}>
              <label htmlFor={item}>
                <input type="radio" name="userRole" value={item} id={item} checked={role === item} />
                <div className="label">
                  {item}
                </div>
              </label>
            </div>
          ))}
        </div>
        {features.map((feature) => (

          <div key={feature.id} className={`feature-wrapper ${role}`}>
            <div className={`text ${role === 'Waldbesitzer' ? 'active' : ''}`}>
              {feature.waldbesitzer}
            </div>

            <div className={`text ${role === 'Spediteur' ? 'active' : ''}`}>
              {feature.spediteur}

            </div>
            <div className="slider-wrapper">
              <div className={`window ${role === 'Waldbesitzer' ? 'active' : ''}`} />
              <div className={`image ${role === 'Waldbesitzer' ? feature.WBcolor : feature.SPcolor}`}>

                <Image
                  fluid={WB2.filter((image) => image.fluid.src.includes(role === 'Waldbesitzer' ? feature.WBimg : feature.SPimg))[0].fluid}
                />
              </div>
              <div className={`window ${role === 'Spediteur' ? 'active' : ''}`} />
            </div>
          </div>
        ))}

      </div>
    </section>
  );
};

export default DescriptionSection;
