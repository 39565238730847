import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Appstore from '../../static/images/appstore-badge.png';
import Playstore from '../../static/images/google-play-badge.png';

const DownloadsSection = () => {
  const data = useStaticQuery(graphql`
   {
        imageSharp(fluid: {originalName: {eq: "devices.png"}}) {
        fluid {
            ...GatsbyImageSharpFluid_withWebp
             }
         }
    }
`);

  const buttonStyle = { width: '100%', height: 'auto' };

  return (
    <section id="downloads">
      <div className="fixed-width-sm">
        <Image fluid={data.imageSharp.fluid} />
        <div className="wrapper-label">
          <a href="https://play.google.com/store/apps/details?id=de.intend.rupo" target="_new">
            <img
              src={Playstore}
              style={buttonStyle}
              alt="Laden im App Store"
            />
          </a>
          <a href="https://apps.apple.com/de/app/rundholzportal/id1438520763" target="_new">
            <img
              src={Appstore}
              style={buttonStyle}
              alt="Jetzt bei Google Play"
            />
          </a>
        </div>
      </div>

    </section>
  );
};

export default DownloadsSection;
