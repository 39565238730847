import React from 'react';

const Navbar = () => (
  <div className="topbar inverted">
    <div className="navigation-wrapper">

      <a href="/#" title="home">
        <div className="logo nav-link" />
      </a>
      <ul className="navigation">
        <a href="https://www.rundholzportal.de/Account/Login">
          <li className="nav-link">
            <span>
              Anmelden
            </span>
          </li>
        </a>
      </ul>
    </div>
  </div>
);

export default Navbar;
