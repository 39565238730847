import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Video from '../components/video';
import DescriptionSection from '../components/DescriptionSection';
import DownloadsSection from '../components/DownloadsSection';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section id="video">
      <div id="video-container" className="content-wrapper fixed-width-md">
        <h1>Endlich eine digitale Lösung</h1>
        <p>
          Das Rundholzportal per Web oder App, digital vom Wald bis in das Sägewerk.  Die Teilnahme für Waldbesitzer und Spediteure ist kostenlos.
        </p>
        <Video parent="video-container" />
      </div>
    </section>
    <section>
      <div className="content-wrapper fixed-width-md">

        <h1>Digital vom Wald bis in das Sägewerk</h1>
        <p>
          Holzverarbeiter müssen jede Einschlagssaison ihre Nachfrage für das Jahr abdecken.
        </p>
        <p>
          Das Rundholzportal ermöglicht Waldbesitzer und Spediteure ihre Kapazitäten anhand der aktuelle Nachfrage besser abzustimmen.
        </p>
      </div>
    </section>
    <DescriptionSection />
    <DownloadsSection />
  </Layout>
);

export default IndexPage;
