import React from 'react';

const Footer = () => (

  <footer className="footer">
    <a href="/#" title="home">
      <div className="logo nav-link" />
    </a>
    <div className="row-wrapper">
      <div>
        <span className="copyright">{new Date().getFullYear()}</span>
        ©
        RundholzPortal GmbH
      </div>
      <nav>
        <a href="https://www.rundholzportal.de/Info/Impressum">
          Impressum
        </a>
        <a href="https://www.rundholzportal.de/Info/Datenschutz">
          Datenschutz
        </a>
      </nav>
    </div>
  </footer>
);

export default Footer;
