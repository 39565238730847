import React from 'react';
import PropTypes from 'prop-types';

import Footer from './footer';
import Navbar from './navbar';

import '../styles/site.scss';
import Stage from './stage';

const Layout = ({ children }) => (
  <div className="public">
    <div className="wrapper">
      <Navbar />
      <Stage />
      <main className="main-panel">
        {children}
      </main>
      <Footer />
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
